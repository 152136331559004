import * as React from "react"
import { Container, Row } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../components/css/NewsDetail.scss"
import { Link } from "gatsby"
import { AiFillLinkedin } from "react-icons/ai"
import { FaTwitter } from "react-icons/fa"
import { FaYoutube } from "react-icons/fa"
import { FiArrowLeft } from "react-icons/fi"
import glomoimagejpg from "../images/media/Glomo_award_Img.jpg"

const EdgeqPressKit8 = () => (
  <Layout>
    <Seo title="EdgeQ | EdgeQ Wins Multiple 2023 Global Mobile (GLOMO)" />

    <section className="News-detail">
      <Container>
        <Row>
          <div className="detail-head">
            <Link to="/media/#August" className="back-btn">
              <FiArrowLeft /> Company Announcements
            </Link>

            <h2>
              EdgeQ Wins Multiple 2023 Global Mobile (GLOMO) Awards Including
              the Coveted CTO Choice Award for Outstanding Mobile Technology
            </h2>
          </div>

          <div className="detail-para">
            <p className="text-left">
              <i>
                At MWC Barcelona, EdgeQ Wins “CTO Choice Award for Outstanding
                Mobile Technology” and “Best Digital Technology Breakthrough for
                Companies with Under $10M Annual Global Revenue”
              </i>
            </p>

            <div className="ajit-social">
              <ul className="social-icons">
                <li>
                  <a
                    aria-label="Save"
                    href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFQA4el-DoX9AAAAX7t1dUotlbczx1ZDTlCKPTejfiFEWL5GGZd5oqr6zWvtFKLF3CnnFGCCLco9AXaX4nEgd1nxfFvszEVY7-NA8YXEk9NExe7VTUUStGaSa7qf9cyJahXczA=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fedgeq"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <AiFillLinkedin />
                  </a>
                </li>
                <li>
                  <a
                    aria-label="Save"
                    href="https://twitter.com/edgeq_inc?lang=en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTwitter />
                  </a>
                </li>
                <li>
                  <a
                    aria-label="Save"
                    href="https://www.youtube.com/channel/UChEG47AbYVKYw46EGhE_dcg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaYoutube />
                  </a>
                </li>
              </ul>
            </div>

            <div className="glomo_img">
            <img src={glomoimagejpg} alt="" />
            </div>
            <p>
              <b>SANTA CLARA, CA</b> – March 9, 2023 –{" "}
              <Link to="/">EdgeQ Inc</Link>, a leader in 5G wireless
              infrastructure, and Vodafone, a leader in 5G wireless
              infrastructure, won two prestigious awards at this year’s 2023
              Global Mobile (GLOMO) Awards at MWC Barcelona. As the only
              semiconductor and startup company winner, EdgeQ won in the
              categories of “Best Digital Tech Breakthrough for Companies with
              Under $10 Million Annual Global Revenue” and the highly coveted
              “CTO Choice Award for Outstanding Mobile Technology.” The CTO
              Choice Award was chosen by a panel of more than 20 distinguished
              CTOs from every continent, selecting the overall technology winner
              from each of the seven best “Mobile Tech” award winners.
            </p>

            <p>
              EdgeQ has developed the world’s first “Base station-on-a-Chip,”
              giving operators, cloud service providers and enterprises the
              revolutionary ability to build, configure, and deploy public and
              private networks that are simple, scalable, and affordable.
              Completely open and software programmable, the converged 4G+5G+AI
              platform enables operators and service providers to deploy systems
              at scale and effect wholesale upgrades without ripping and
              replacing their current network configuration.
            </p>

            <p>
              John Hoffman, CEO of GSMA Ltd commented: “In another year when
              staying connected proved to be more important than ever, we
              received an incredible number of entries showcasing amazing
              innovation which is redefining what it means to work together.
              Huge congratulations to EdgeQ. The GLOMO Awards were set up to the
              most forward-looking innovation and sheer brilliance our sector
              has to offer, and EdgeQ’s 5G+AI base station-on-a-chip undeniably
              fits the bill.”
            </p>

            <p>
              GLOMO Award Judges said: “The ongoing reticence over ORAN and in
              particular the need for legions of software programmers and
              endless SI cycles are potentially swept away by EdgeQ’s 5G + AI
              Base Station-on-a-Chip having all this in ever stable silicon (and
              all of this without starting on the potential power savings).”
            </p>

            <p>
              To showcase the versatility and scalability of its Base
              Station-on-a-Chip, EdgeQ pioneered two groundbreaking industry
              precedences at MWC Barcelona: A programmable, all-in-one,
              multi-user, 4G+5G+AI small cell delivering over 2Gbps of
              throughout at half the power, and industry’s very first
              end-to-end, 32 antennas, Open RAN massive MIMO operationalized
              with over 5 Gbps of throughput running at {"<50W"}. The EdgeQ Base
              station-on-a-Chip is now available to the general market.
            </p>

            <p>
              “The Global Mobile Award is the industry’s most prestigious
              accolade. To be recognized by this sector’s most prominent and
              established experts as a technology innovator is a tremendous
              honor. I share this award with a team of fierce colleagues who
              have dared boldly, persevered tenaciously, and grinded tirelessly.
              This is to their heart, their sweat, and their inspired spirit,”
              said Hari Gangadharan, Vice President and Head of Silicon
              Engineering at EdgeQ.
            </p>

            <p>
              More information as to specific category criteria and judges is
              available on the GLOMO Awards{" "}
              <Link
                target="_blank"
                to="https://www.mwcbarcelona.com/mobile-awards/categories-and-awards"
              >
                website
              </Link>
              .
            </p>

            <p>
              To learn more about how EdgeQ is pioneering the 5G infrastructure
              market, please visit <Link to="/">www.edgeq.io</Link>.
            </p>

            <b>About EdgeQ</b>
            <br />
            <br />
            <p>
              EdgeQ is a leading innovator in 5G systems-on-a-chip. The company
              is headquartered in Santa Clara, CA, with offices in San Diego, CA
              and Bangalore, India. Led by executives from Qualcomm, Intel, and
              Broadcom, EdgeQ is pioneering converged connectivity and AI that
              is fully software-customizable and programmable. The company is
              backed by world-renowned investors. To learn more about EdgeQ,
              visit <Link to="/">www.edgeq.io</Link>
            </p>
            {/* <b>Contacts</b>
              <br />
              <br />
              <a target="_blank" href="https://goo.gl/maps/qwqsKwcTcEVokx1e6">Jordan Tewell, 10Fold Communications</a>
              <br />
              <a href="mailto:edgeq@10fold.com">edgeq@10fold.com</a>
              <br />
              <a href="tel:+4156666066">(415) 666-6066</a> */}
          </div>
        </Row>
      </Container>
    </section>
  </Layout>
)

export default EdgeqPressKit8
